<template>
	<div class="wrap">
		<el-page-header @back="$router.back()" :content="title" class="margin-bottom margin-top-xs"></el-page-header>
		<el-alert title="操作提示" type="success" class="margin-bottom">
			<div>
				1. 标识“*”的选项为必填项，其余为选填项。<br />
				2. 请按提示栏信息进行商品添加。
			</div>
		</el-alert>
		<div class="box">
			<div class="form_box">
				<!-- {{form.permissions}} -->
				<el-form ref="form" :model="form" label-width="80px" v-loading="loading">
					<el-form-item label="项目名称" prop="server_name" :rules="rules.required">
						<el-input v-model="form.server_name"></el-input>
						<span class="text-grey margin-left">服务名称最多可输入100个字符！</span>
					</el-form-item>
					<el-form-item label="项目分类" prop="classify" :rules="rules.required">
						<el-select v-model="form.classify" placeholder="请选择">
							<el-option v-for="item in classify" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="项目描述" prop="des">
						<el-input v-model="form.des" type="textarea" :autosize="{ minRows: 4}"></el-input>
					</el-form-item>
					<el-divider></el-divider>
					<el-form-item label="项目售价" prop="current_price" :rules="rules.required">
						<el-input-number v-model="form.current_price" :precision="2" :step="10" :max="9999" :min="0"></el-input-number>
					</el-form-item>
					<el-form-item label="原价" prop="original_price">
						<el-input-number v-model="form.original_price" :precision="2" :step="10" :max="9999" :min="0"></el-input-number>
					</el-form-item>
					<el-form-item label="成本价" prop="cost_price">
						<el-input-number v-model="form.cost_price" :precision="2" :step="10" :max="9999" :min="0"></el-input-number>
					</el-form-item>
					<el-form-item label="销售提成" prop="sales_commission">
						<el-input-number v-model="form.sales_commission" :precision="2" :step="10" :max="9999" :min="0"></el-input-number>
					</el-form-item>
					<el-form-item label="服务提成" prop="server_commission">
						<el-input-number v-model="form.server_commission" :precision="2" :step="10" :max="9999" :min="0"></el-input-number>
						<div class="text-sm text-grey">该提成为参考（默认）提成，具体提成以技师管理中的提成配置为准！</div>
					</el-form-item>
					<el-divider></el-divider>
					<el-form-item label="缩略图" prop="cover">
						<el-upload class="avatar-uploader" :action="$api.common.UPLOAD_URL" :show-file-list="false" :on-success="coverUploadSuccess"
						:before-upload="beforeCoverUpload">
							<img v-if="form.cover" :src="form.cover" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</el-form-item>
					<el-form-item label="上架">
						<el-switch v-model="form.status" :active-value="1" :inactive-value="0"></el-switch>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" @click="onSubmit()">保存</el-button>
						<!-- <el-button type="primary" @click="onSubmit()">保存并继续添加</el-button> -->
						<el-button @click="$router.back()">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>
<script>
	import rules from '../../common/common.rules.js'
	export default {
		data() {
			return {
				rules,
				classify: [],
				loading: false,
				form: {
					id: this.$route.params.id, // int 服务ID
					server_name: undefined, // string 服务名称
					classify: undefined, // int 分类ID
					cover: undefined, // string 封面缩略图
					des: undefined, // string 服务描述
					cost_price: 0, // decimal 成本价格
					original_price: 0, // decimal 原价
					current_price: 1, // decimal 现价
					sales_commission: 0, // decimal 销售提成
					server_commission: 0, // decimal 服务提成
					status: 1, // 上架
				}
			};
		},
		computed: {
			title() {
				if (this.form.id) {
					return '编辑项目'
				} else {
					return '新增项目'
				}
			}
		},
		mounted() {
			// 获取项目分类
			this.$api.classify.getClassify(this.$api.classify.SERVICE).then(res => {
				this.classify = res.data
				this.classify = this.classify.filter(item => item.is_show!=0)
			})
			if (this.form.id) {
				this.$api.project.getProject({
					id: this.form.id
				}).then(res => {
					this.form = res.data
				})
			}
		},
		methods: {
			onSubmit() {
				this.$refs.form.validate(valid => {
					if (valid) {
						const loading = this.$loading();
						this.$api.project.save(this.form).then(res => {
							loading.close();
							if (res.code == 200) {
								// 保存成功
								this.$message.success(`保存成功`);
								this.$router.back();
							} else {
								this.$message.error(res.msg);
							}
						}).catch(err => {
							loading.close();
							this.$alert(err);
						});
					} else {
						this.$message.warning(`请检查输入错误`);
						return false;
					}
				});
			},
			coverUploadSuccess(res) {
				this.form.cover = res.data
				this.loading = false;
				// this.form.cover = URL.createObjectURL(file.raw);
			},
			beforeCoverUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				// const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('缩略图只能是 JPG 格式!');
				}
				// if (!isLt2M) {
				// 	this.$message.error('上传头像图片大小不能超过 2MB!');
				// }
				this.loading = true;
				return isJPG;
			}
		},
	};
</script>

<style scoped lang="scss">
	.form_box {

		.el-input,
		.el-textarea {
			width: 500px;
		}
	}

	.avatar-uploader>>>.el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;

		&:hover {
			border-color: #409EFF;
		}
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 100px;
		height: 100px;
		line-height: 100px;
		text-align: center;
	}

	.avatar {
		width: 100px;
		height: 100px;
		display: block;
	}
</style>
